import { Box, Checkbox, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Button from "components/CustomButtons/Button";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import { configUrl } from "config";
import { LoginContext } from "contexts/LoginContext";
import { useContext, useEffect, useState } from "react";
import providerResourceMapping from "services/ProviderServices/providerResourceMapping";
import getAllCourses from "services/TestSeriesService/getAllCourses";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDataGrid-cell": {
      display: "flex",
      alignItems: "center",
    },
  },
  input: {
    width: "100px",
  },
  errorInput: {
    borderColor: "red", // Highlighting error field
  },
}));

const AggregatorResourceListing = ({
  providerIdEdit,
  afterFinish,
  contentProviderIds,
}) => {
  const classes = useStyles();
  const { user, providerId, providerType } = useContext(LoginContext);
  const [testSeries, setTestSeries] = useState([]);
  const [selectedSeries, setSelectedSeries] = useState({});
  // const [pageSize, setPageSize] = useState(10);
  // const [loading, setLoading] = useState(false);
  const [submitloading, setSubmitloading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessages] = useState("");
  const [showAlert, setShowAlert] = useState(null);
  const [flag, setFlag] = useState(false);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const fetchExistingMappings = async () => {
    try {
      const response = await axios.get(
        `${configUrl.AssessmentServer}/api/ProviderResourceMapping/${providerIdEdit}`
      );
      const mappings = response.data.$values || [];
      const formattedMappings = mappings.reduce((acc, item) => {
        acc[item.resourceId] = {
          testsCount: item.procureStudentsCount || 0,
          availableStudentsCount: item.licenseStudentsCount || 0,
        };
        return acc;
      }, {});
      setSelectedSeries(formattedMappings);
    } catch (error) {
      console.error("Error fetching provider resource mapping:", error);
    }
  };

  useEffect(() => {
    fetchExistingMappings();
  }, [providerIdEdit]);

  const fetchTestSeries = async (pageNumber, pageSize) => {
    setPageState((old) => ({ ...old, isLoading: true }));
    try {
      const response = await getAllCourses.getAllCourses(
        pageNumber,
        pageSize,
        2,
        contentProviderIds?.join(",")
      );
      const data = response || [];
      setTestSeries(data);
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: data,
        total: (data?.length > 0 && data[0]?.totalCount) || 0,
      }));
    } catch (error) {
      console.error("Error fetching test series:", error);
    } finally {
      if (pageState.isLoading == true) {
        setPageState((old) => ({
          ...old,
          isLoading: false,
        }));
      }
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedSeries((prevState) => {
      const updatedState = {
        ...prevState,
        [id]: { ...prevState[id], selected: !prevState[id]?.selected },
      };
      // Update the save button state
      setIsSaveEnabled(
        Object.values(updatedState).some((series) => series.selected)
      );
      return updatedState;
    });
  };

  const handleInputChange = (id, count) => {
    setSelectedSeries((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], testsCount: count },
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [id]: false })); // Reset error state on input change
  };

  const handleSubmit = async () => {
    let valid = true;
    setSubmitloading(true);
    const selected = Object.entries(selectedSeries).reduce(
      (result, [id, data]) => {
        if (data.selected) {
          if (!data.testsCount || isNaN(data.testsCount)) {
            setErrors((prevErrors) => ({ ...prevErrors, [id]: true }));
            valid = false;
            setSubmitloading(false);
          } else {
            result.push({
              id: 0,
              providerId: providerIdEdit,
              resourceTypeId: 3,
              resourceId: id,
              resourceImage: null,
              studentsCount: parseInt(data.testsCount),
              createdBy: user,
              createdDate: new Date().toISOString(),
              updatedBy: user,
              updatedDate: new Date().toISOString(),
            });
          }
        }
        return result;
      },
      []
    );

    if (!valid) {
      return; // If validation fails, don't proceed with API call
    }

    try {
      const postResourceResp = await providerResourceMapping.providerResourceMapping(
        providerType === 5 ? providerId : null,
        selected
      );

      if (postResourceResp.status === 200 || postResourceResp.status === 201) {
        setShowAlert(
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            severity="success"
            alertMessage={"Changes added successfully."}
          />
        );
        await fetchExistingMappings();
        setTimeout(() => {
          afterFinish();
        }, 2500);
      } else if (postResourceResp.status === 400) {
        setSubmitloading(false);
        setShowAlert(
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            severity="error"
            alertMessage={postResourceResp?.messege || "Error saving provider resource mapping"}
          />
        );
        await fetchExistingMappings();
        setTimeout(() => {
          afterFinish();
        }, 2500);
      }
    } catch (error) {
      setSubmitloading(false);
      console.error("Error saving provider resource mapping:", error);
    }
  };

  useEffect(() => {
    console.log("pagestate", pageState);
    if (pageState.page > 0) {
      fetchTestSeries(pageState.page, pageState.pageSize);
    }
  }, [pageState.page, pageState.pageSize]);

  const columns = [
    {
      field: "checkbox",
      headerName: "#",
      width: 50,
      renderCell: (params) => (
        <Checkbox
          checked={!!selectedSeries[params.row.id]?.selected}
          onChange={() => handleCheckboxChange(params.row.id)}
          disabled={providerType == 5 && (!selectedSeries[params.row.id]?.availableStudentsCount || "" || null)}
        />
      ),
    },
    { field: "providerName", headerName: "Content Partner", width: 200 },
    { field: "text", headerName: "Test Series", width: 300 },
    {
      field: "availableStudentsCount",
      headerName: "License Available",
      width: 200,
      renderCell: (params) => (
        <TextField
          type="number"
          className={classes.input}
          value={selectedSeries[params.row.id]?.availableStudentsCount || ""}
          disabled
          InputProps={{
            disableUnderline: true, // as discussed with sir removes the underline border
            style: {
              border: "none",
            },
          }}
        />
      ),
    },
    {
      field: "testsCount",
      headerName: "Procure License *",
      width: 200,
      renderCell: (params) => (
        <TextField
          type="number"
          className={`${classes.input} ${errors[params.row.id] ? classes.errorInput : ""
            }`}
          value={selectedSeries[params.row.id]?.testsCount || ""}
          onChange={(e) => handleInputChange(params.row.id, e.target.value)}
          disabled={!selectedSeries[params.row.id]?.selected}
          error={!!errors[params.row.id]} // Set error flag
          helperText={errors[params.row.id] ? "Required" : ""}
        />
      ),
    },
    // {
    //   field: "new", headerName: "New field", width: 200,
    //   renderCell: (params) => (
    //     <TextField
    //       type="number"
    //       className={`${classes.input} ${errors[params.row.id] ? classes.errorInput : ""
    //         }`}
    //       value={selectedSeries[params.row.id]?.testsCount || ""}
    //       onChange={(e) => handleInputChange(params.row.id, e.target.value)}
    //       disabled={!selectedSeries[params.row.id]?.selected}
    //       error={!!errors[params.row.id]} // Set error flag
    //       helperText={errors[params.row.id] ? "Required" : ""}
    //     />
    //   ),

    // },
  ];

  return (
    <Box className={classes.root} style={{ height: 450, width: 969 }}>
      <div style={{ display: "flex", height: "100%" }}>
        {pageState.isLoading == false && pageState.data ? (
          <CustomDataGrid
            rows={pageState.data}
            columns={columns}
            page={pageState.page - 1}
            pageSize={pageState.pageSize}
            rowsPerPageOptions={[10, 30, 50, 100]}
            rowCount={pageState.total}
            onPageChange={(newPage) =>
              setPageState((old) => ({
                ...old,
                page: newPage + 1,
              }))
            }
            onPageSizeChange={(newPageSize) =>
              setPageState((old) => ({
                ...old,
                pageSize: newPageSize,
              }))
            }
            paginationMode="server"
            // onPageChange={(params) => fetchTestSeries(params.page + 1, pageSize)}
            loading={pageState.isLoading}
            disableSelectionOnClick
          />
        ) : (
          <div style={{ height: 650, width: "100%" }}>
            <CustomDataGrid
              rows={[]}
              loading={pageState.isLoading}
              columns={columns}
            // page={0}
            // pageSize={0}
            // getRowClassName={getRowClassName}
            />
          </div>
        )}
      </div>
      <Box mt={2} style={{ float: "right" }}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!isSaveEnabled || pageState.isLoading || submitloading || testSeries.length === 0} // Save button only enabled if at least one selected
        >
          {
            submitloading ?
              'Saving...' : 'Save'
          }
        </Button>
      </Box>
      {
        <CustomAlert
          open={error}
          severity="error"
          alertMessage={message}
        />
      }
      {showAlert}
    </Box>
  );
};

export default AggregatorResourceListing;
